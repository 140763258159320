/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-spread */
// NOTE: this is also used for CardsMobile component
import React from 'react';
import PropTypes from 'prop-types';

function Dot(props) {
  const { selected } = props;
	return (
		<span
			data-spec="dot-wrapper"
			style={{
				display: 'inline-block',
				height: '12px',
				width: '12px',
				borderRadius: '10px',
				backgroundColor: '#0568ae',
				margin: '7px 5px',
				opacity: selected ? '1' : '0.3',
				transitionDuration: '300ms'
			}}
		/>
	);
}
Dot.propTypes = {
	selected: PropTypes.any
};

export default function IndicatorDots(props) {
  const { total, index } = props;
	const wrapperStyle = {
		position: 'absolute',
		width: '100%',
		zIndex: '100',
		bottom: '0px',
		textAlign: 'center'
	};

	if (total < 2) {
		// Hide dots when there is only one dot.
		return <div style={wrapperStyle} data-spec="one-dot-wrapper" />;
	}
  return (
    <div style={wrapperStyle} data-spec="multiple-dot-wrapper">
      {Array.apply(null, Array(total)).map((x, i) => <Dot data-spec="dot" key={i} selected={index === i} />)}
    </div>
  );
}

IndicatorDots.propTypes = {
	index: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired
};
