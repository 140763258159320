export default function saveReduxToWindowObject() {
  // Dynamically import the store only when this function is called
  import('redux/store').then(({ default: store }) => {
    if (store) {
      window.reactRedux = store;
    }
  }).catch((err) => {
    console.error('Failed to load the store', err);
  });
  return null;
}
