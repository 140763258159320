import React from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from '@nivo/pie';
import { FormattedMessage, injectIntl } from 'react-intl';

import NoData from '../NoChartData';
import { renderUnit, renderValue, getDataColor } from '../Helpers/helpers';
// import { onChartClick } from '../../../services/DashboardService/dashboardHelpers';

import styles from './PieChart.scss';

const PieChart = ({
	chartData,
	isAnalytics,
	gridItem,
	isModal,
	isTicketingCharts,
	intl,
}) => {
	if (!chartData || chartData.length === 0) {
		return (
			<div data-spec="no-data">
				<NoData />
			</div>
		);
	}

	const uniqIds = chartData
		.map((item) => item[gridItem.groupByColumn] || 'null')
		.filter((value, index, self) => self.indexOf(value) === index);
	let tempChartData = chartData.map((item) => item);

	if (gridItem.aggregationFunction === 'MVA') {
		tempChartData = chartData.map((data) => (
			{
				...data,
				value: data.value !== null
					? parseFloat(data.value_2) / parseFloat(data.value)
					: 0
			}
		));
	}

	const pieChartData = uniqIds.map((id, index) => ({
		id: id || 'null',
		label: id || 'null',
		color: getDataColor(id, index),
		value: tempChartData
			.filter((item) => item[gridItem.groupByColumn] === id || id === 'null')
			.reduce(
				(total, current) =>
					total + parseFloat(current.value === null ? 0 : current.value),
				0
			)
	}));

	pieChartData.total = pieChartData.reduce(
		(total, current) => total + current.value,
		0
	);

	const getTicketingColors = (id) => {
		const colors = [
			{ id: 'Critical', color: '#cf2a2a' },
			{ id: 'Major', color: '#ea7400' },
			{ id: 'Minor', color: '#4ca90c' }
		];

		return colors[colors.findIndex((color) => color.id === id)].color;
	};

	const renderTooltip = (values, total, units) => {
		const { id, value = 0, color } = values.datum.data;

		return (
			<div
				data-spec="pie-chart-custom-tooltip"
				className={styles.customTooltip}
			>
				{isAnalytics ? (
					<div className={styles.analytics_tooltip}>
						<div className={styles.medium_font}>
							<span>
								<FormattedMessage id="VALUE" defaultMessage="Value" />
								:
							</span>
							<span>
								{renderValue(units, value)}
								{' '}
								{renderUnit(units, value, intl.formatMessage)}
							</span>
						</div>
						<span>{id}</span>
					</div>
				) : (
					<>
						<span
							style={{ backgroundColor: color }}
							className={styles.customTooltipBlock}
						/>
						{`${id}: ${value.toLocaleString()} - ${(
							(value / total) *
							100
						).toFixed(2)}%`}
					</>
				)}
			</div>
		);
	};

	const renderTooltipMain = (values, total, units) => <div
		data-spec="pie-chart-custom-tooltip"
		className={styles.customTooltipMain}
	>
		{renderTooltip(values, total, units)}
                                                     </div>;

	return (
		<div data-spec="pie-chart" className={`${styles.chart_wrapper}`}>
			<div className={styles.main_wrapper}>
				<main className={styles.main} data-spec="pie-chart-main">
					{gridItem.showSummary && (
						<div className={styles.total}>
							<div className={styles.total_text}>
								<FormattedMessage id="TOTAL" defaultMessage="Total" />
							</div>
							<div
								className={
									!isModal ? styles.total_value : styles.total_value_modal
								}
							>
								<span>{renderValue(gridItem.units, pieChartData.total)}</span>
								<span>
									{renderUnit(
										gridItem.units,
										pieChartData.total,
										intl.formatMessage
									)}
								</span>
							</div>
						</div>
					)}
					<ResponsivePie
						data={pieChartData}
						margin={{
							top: 20,
							right: 20,
							bottom: isTicketingCharts ? 100 : 20,
							left: 20
						}}
						colors={
							isTicketingCharts
								? ({ id }) => getTicketingColors(id)
								: (slice) => slice.data.color
						}
						innerRadius={0.9}
						padAngle={1}
						cornerRadius={45}
						arcLinkLabelsDiagonalLength={10}
						arcLinkLabelsStraightLength={10}
						arcLinkLabelsTextOffset={0}
						arcLinkLabelsSkipAngle={20}
						enableArcLabels={false}
						animate={false}
						arcLabelsTextColor="#ffffff"
						isInteractive
						tooltip={(values) =>
							renderTooltipMain(values, pieChartData.total, gridItem.units)}
						legends={
							isTicketingCharts
								? [
									{
										anchor: 'bottom',
										direction: 'row',
										translateY: 50,
										itemWidth: 100,
										itemHeight: 18,
										symbolSize: 18,
										symbolShape: 'circle'
									}
								]
								: []
						}
					/>
				</main>
			</div>
		</div>
	);
};

const {
	string,
	object,
	bool,
	arrayOf,
	objectOf,
	oneOfType,
	number,
	func,
	symbol,
} = PropTypes;

PieChart.propTypes = {
	chartData: arrayOf(object),
	isAnalytics: bool,
	gridItem: objectOf(oneOfType([string, object, number, bool])),
	isModal: bool,
	isTicketingCharts: bool,
	intl: objectOf(oneOfType([object, func, string, symbol])),
};

PieChart.defaultProps = {
	chartData: [],
	isTicketingCharts: false,
	isAnalytics: false,
	gridItem: {},
	isModal: false,
	intl: {}
};

export default injectIntl(PieChart);
