import PropTypes from 'prop-types';
import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { format, compareAsc } from 'date-fns';
import { injectIntl } from 'react-intl';

import NoData from '../NoChartData';
import {
	isHistoricalCycle,
	renderUnit,
	renderValue,
	getDataColor
} from '../Helpers/helpers';

import styles from './LineChart.scss';

const LineChart = ({ chartData, gridItem, isWide, intl, dataGridSize }) => {
	if (!chartData || chartData.length === 0) {
		return (
			<div
				data-spec="no-data"
				className={isWide ? styles.chart_wrapper_wide : styles.chart_wrapper}
			>
				<NoData />
			</div>
		);
	}

	let tempChartData = chartData.map((item) => item);

	if (gridItem.aggregationFunction === 'MVA') {
		tempChartData = chartData.map((item) => ({
			...item,
			value: parseFloat(item.value_2 === null ? 0 : item.value_2) / parseFloat(
				item.value === 0 || item.value === null ? 1 : item.value
			),
		}));
	}

	const incrementDate = gridItem.incrementBy
		.split(',')
		.filter((item) => item !== 'value_2');
	const maxValue = tempChartData.reduce(
		(value, currentItem) =>
		(value < parseFloat(currentItem.value)
			? parseFloat(currentItem.value)
			: value),
		0
	);
	const groupByAttr = tempChartData
		.map((item) => item[gridItem.groupByColumn])
		.filter((value, index, self) => self.indexOf(value) === index);
	const lineChartData = groupByAttr.map((item, index) => ({
		id: item || 'null',
		data: [],
		color: getDataColor(item, index)
	}));

	const checkDate = (date) => {
		const dateToBeChecked = new Date(date);
		const newDate = new Date();

		return dateToBeChecked instanceof Date
			&& !Number.isNaN(dateToBeChecked.getTime())
			? date
			: `${newDate.getFullYear()}-${newDate.getMonth()}-${newDate.getDate()}`;
	};

	lineChartData.forEach((item) => {
		tempChartData.forEach(
			(row) =>
				item.id === (
					row[gridItem.groupByColumn] || 'null'
				) &&
				item.data.push({
					x: !isHistoricalCycle(gridItem.groupByDateId)
						? checkDate(incrementDate
							.map((date) =>
							(
								`${row[date]}`.length < 2 ? `0${row[date]}` : row[date]
							))
							.join('-'))
						: intl.formatMessage({
							id: 'CHARTS_BILLING_CYCLE_ABBREVIATION',
							defaultMessage: 'BC {billcycle}'
						}, { billcycle: row.billcycle }),
					y: parseFloat(row.value === null ? 0 : row.value)
				})
		);

		if (dataGridSize === 'sm') {
			item.data = item.data.slice(-9);
		}
		if (dataGridSize === 'md') {
			item.data = item.data.slice(-15);
		}
	});

	const showBottomAxis = () => {
		let composedDates = [];

		lineChartData.forEach((data) =>
			data.data.forEach((item) => composedDates.push(item.x)));
		composedDates = composedDates
			.filter((value, index, self) => self.indexOf(value) === index)
			.sort((date1, date2) =>
				compareAsc(new Date(`${date1}`), new Date(`${date2}`)));

		return composedDates
			.map((date, index) => {
				const len = composedDates.length;
				let modValue = 2;

				if (len > 24) modValue = 5;
				else if (len > 20) modValue = 3;

				if (
					(index % modValue === 0 && index !== len - 2) ||
					index === len - 1 ||
					len === 2
				) {
					const formattedDate = date.split('-');

					return new Date(
						parseInt(formattedDate[0], 10),
						parseInt(formattedDate[1], 10) - 1,
						parseInt(formattedDate[2] || 1, 10)
					);
				}

				return undefined;
			})
			.filter((date) => date !== undefined);
	};

	const formatTooptipDate = (incrementType, formattedDate) => {
		if (!isHistoricalCycle(gridItem.groupByDateId)) {
			switch (incrementType) {
				case 'month': formattedDate = `${format(new Date(formattedDate), 'MMMM yyyy')}`; break;
				case 'week': formattedDate = `${format(new Date(formattedDate), 'MMM')} Wk ${format(new Date(formattedDate), 'd')}`; break;
				default: formattedDate = `${format(new Date(formattedDate), 'MMM d')}`;
			}
		}
		return formattedDate;
	};

	const renderTooltip = (values, units, incrementType) => {
		const { points } = values.slice;

		return points.map((item) => (
			<div
				data-spec="line-chart-custom-tooltip"
				className={styles.customTooltip}
				key={item.serieId}
			>
				<span
					style={{ backgroundColor: item.serieColor }}
					className={styles.customTooltipBlock}
				/>
				<span>
					{`${formatTooptipDate(incrementType, item.data.x)} - `}
					{renderValue(units, item.data.y)}
					{' '}
					{renderUnit(units, item.data.y, intl.formatMessage)}
					{' '}
					{item.serieId}
				</span>
			</div>
		));
	};

	const renderTooltipMain = (values, units, incrementType) => <div
		data-spec="line-chart-custom-tooltip"
		className={styles.customTooltipMain}
	>
			{renderTooltip(values, units, incrementType)}
                                                             </div>;
	return (
		<div
			data-spec="line-chart-wrapper"
			className={isWide ? styles.chart_wrapper_wide : styles.chart_wrapper}
		>
			<main className={styles.main}>
				<div className={styles.side_unit}>
					{renderUnit(gridItem.units, 0, intl.formatMessage)}
				</div>
				<ResponsiveLine
					data={lineChartData}
					margin={{
						top: 15,
						right: 30,
						bottom: 40,
						left: 60,
					}}
					curve="monotoneX"
					colors={(data) => data.color}
					enableGridX={false}
					yScale={{
						type: 'linear',
						stacked: false,
						min: 0,
						max: maxValue ? maxValue * 1.2 : 'auto'
					}}
					xScale={
						!isHistoricalCycle(gridItem.groupByDateId)
							? {
								type: 'time',
								precision: incrementDate.length === 3 ? 'day' : 'month',
								format: incrementDate.length === 3 ? '%Y-%m-%d' : '%Y-%m'
							}
							: { type: 'point' }
					}
					axisBottom={
						!isHistoricalCycle(gridItem.groupByDateId)
							? {
								tickSize: 0,
								tickRotation: 0,
								tickPadding: 20,
								tickValues: showBottomAxis(),
								format: (value) => {
									switch (gridItem.incrementType) {
										case 'month': return `${format(value, 'MMMM y')}`;
										case 'week': return `${format(value, 'MMM')} Wk ${format(value, 'd')}`;
										default: return `${format(value, 'MMM d')}`;
									}
								}
							}
							: { tickSize: 0, tickRotation: 0, tickPadding: 20 }
					}
					axisLeft={{
						tickSize: 0,
						tickPadding: 10,
						format: (value) => `${renderValue(gridItem.units, value, true)}`
					}}
					enableSlices="x"
					sliceTooltip={(values) =>
						renderTooltipMain(values, gridItem.units, gridItem.incrementType)}
					pointSize={14}
					pointBorderWidth={4}
					pointBorderColor="#ffffff"
					lineWidth={2}
				/>
			</main>
			<div className={styles.legend}>
				{lineChartData.map((data) => (
					<div
						key={data.id}
						className={styles.legend_item}
						data-spec={`line-chart-legend-item-${data.id}`}
					>
						<span
							className={styles.legend_dot}
							style={{
								backgroundColor: data.color
							}}
						/>
						<span>
							{data.id.length > 10 ? `${data.id.slice(0, 10)}...` : data.id}
						</span>
					</div>
				))}
			</div>
		</div>
	);
};

const {
	object,
	bool,
	oneOfType,
	array,
	objectOf,
	string,
	number,
	func,
	symbol,
} = PropTypes;

LineChart.propTypes = {
	chartData: oneOfType([object, array]),
	gridItem: objectOf(oneOfType([string, number, bool, object])),
	isWide: bool,
	intl: objectOf(oneOfType([object, func, string, symbol])),
	dataGridSize: string,
};

LineChart.defaultProps = {
	chartData: {},
	gridItem: {},
	isWide: false,
	intl: {},
	dataGridSize: 'lg',
};

export default injectIntl(LineChart);
