export default (brand, name, type = null) => {
	let img;
	try {
		img = require(`brands/default/images/${name}`);
	} catch (ex) {
		if (type === 'networkOperator') {
			img = require('brands/default/images/generic-operator.png');
		} else {
			img = require('brands/default/images/svgs/missing-image.svg');
		}
	}

	// Ensure compatibility with Webpack 4's ES module imports
	return img.default || img;
};
