import PropTypes from 'prop-types';
import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { format } from 'date-fns';
import { FormattedMessage, injectIntl } from 'react-intl';

import NoData from '../NoChartData';
import {
	isHistoricalCycle,
	renderUnit,
	renderValue,
	getDataColor
} from '../Helpers/helpers';

import styles from './BarChart.scss';

const BarChart = ({
	chartData,
	gridItem,
	isAnalytics,
	isWide,
	isTicketingCharts,
	intl,
	dataGridSize,
}) => {
	let barChartData = [];
	let keys = [];
	const colorByKeys = {};

	if (!chartData || chartData.length === 0) {
		return (
			<div
				data-spec="no-data"
				className={`${styles.chart_wrapper} ${!isWide && styles.not_wide}`}
			>
				<NoData />
			</div>
		);
	}

	if (isTicketingCharts) {
		keys = chartData
			.map((key) => key[gridItem.groupByColumn])
			.filter((value, index, self) => self.indexOf(value) === index);
		keys.forEach((key, index) => {
			colorByKeys[key] = getDataColor(key, index);
		});
		barChartData = chartData.map((data) => ({
			id: data[gridItem.groupByColumn],
			[data[gridItem.groupByColumn]]: parseInt(data.value, 10)
		}));
	} else {
		const incrementDate = gridItem.incrementBy
			.split(',')
			.filter((item) => item !== 'value_2');

		keys = chartData
			.map((item) => `${item[gridItem.groupByColumn] || 'null'}`)
			.filter((value, index, self) => self.indexOf(value) === index);
		keys.forEach((key, index) => {
			colorByKeys[key] = getDataColor(key, index);
		});

		const uniqIds = chartData
			.map((item) =>
				(!isHistoricalCycle(gridItem.groupByDateId)
					? incrementDate.map((date) => item[date]).join('-')
					: item.billcycle || 'NA'))
			.filter((value, index, self) => self.indexOf(value) === index);

		uniqIds.forEach((id, index) => {
			barChartData.push({ id });
			keys.forEach((key) =>
				chartData
					.filter(
						(data) =>
							`${data[gridItem.groupByColumn] || 'null'}` === key &&
							(!isHistoricalCycle(gridItem.groupByDateId)
								? incrementDate.map((date) => data[date]).join('-')
								: data.billcycle || 'NA') === id
					)
					.forEach((data) => {
						if (
							gridItem.aggregationFunction === 'MVA'
							&& data.value !== null
						) {
							barChartData[index][key] = parseFloat(
								data.value_2 === null ? 0 : data.value_2
							) / parseFloat(data.value);
						} else {
							barChartData[index][key] = parseFloat(
								data.value === null ? 0 : data.value
							);
						}
					}));
		});
	}

	if (dataGridSize === 'sm') {
		barChartData = barChartData.slice(-9);
	}
	if (dataGridSize === 'md') {
		barChartData = barChartData.slice(-15);
	}

	const maxValue = barChartData
		.map((data) =>
			keys
				.map((key) => (data[key] ? data[key] : 0))
				.reduce((value, currentValue) => value + currentValue, 0))
		.reduce((max, currentItem) => (max < currentItem ? currentItem : max), 0);

	const showBottomAxis = () => {
		const bottomAxis = [];

		barChartData.forEach((data, index) => {
			const len = barChartData.length;
			let modValue = 1;

			if (len > 24) modValue = 5;
			else if (len > 20) modValue = 3;
			else if (len > 12) modValue = 2;

			if (modValue === 1) {
				bottomAxis.push(data.id);
			} else if (
				(index % modValue === 0 && index !== len - 2) ||
				index === len - 1
			) {
				bottomAxis.push(data.id);
			}
		});

		return bottomAxis.filter(
			(value, index, self) => self.indexOf(value) === index
		);
	};

	const formatBottomAxis = (value) => {
		if (!isTicketingCharts) {
			if (!isHistoricalCycle(gridItem.groupByDateId)) {
				const dateParameters = value.split('-');

				switch (gridItem.incrementType) {
					case 'week': return `${format(new Date(
						dateParameters[0],
						parseInt(dateParameters[1], 10) - 1,
						dateParameters[2]
					), 'MMM')} Wk ${dateParameters[2]}`;
					case 'month': return `${format(
						new Date(dateParameters[0], parseInt(dateParameters[1], 10) - 1),
						'MMMM y'
					)}`;
					default: return `${format(
						new Date(
							dateParameters[0],
							parseInt(dateParameters[1], 10) - 1,
							dateParameters[2]
						),
						'MMM d'
					)}`;
				}
			}

			return intl.formatMessage({
				id: 'CHARTS_BILLING_CYCLE_ABBREVIATION',
				defaultMessage: 'BC {billcycle}'
			}, { billcycle: value || 'N/A' });
		}

		return ' ';
	};

	const renderTooltip = (values, units) => {
		const { value, id, indexValue } = values;

		if (!isHistoricalCycle(gridItem.groupByDate) && !isTicketingCharts) {
			const formattedString = indexValue.split('-');
			const formattedDate = new Date(
				formattedString[0],
				formattedString[1] - 1,
				formattedString[2] || 1
			);
			let showDate = format(
				formattedDate,
				formattedString.length > 2 ? 'MMM d' : 'MMMM yyyy'
			);

			if (gridItem.incrementType === 'week') {
				showDate = `${format(formattedDate, 'MMM')} Wk ${formattedString[2]}`;
			}

			return (
				<div
					data-spec="bar-chart-custom-tooltip"
					className={styles.customTooltip}
				>
					<div className={styles.analytics_tooltip}>
						<div className={styles.medium_font}>
							<span>
								<FormattedMessage id="DATE" defaultMessage="Date" />
:
							</span>
							<span>
								{showDate}
							</span>
						</div>
						<div className={styles.medium_font}>
							<span>
								<FormattedMessage id="VALUE" defaultMessage="Value" />
:
							</span>
							<span>
								{renderValue(units, value)}
								{' '}
								{renderUnit(units, value, intl.formatMessage)}
							</span>
						</div>
						<span>{id}</span>
					</div>
				</div>
			);
		}

		return (
			<div
				data-spec="bar-chart-custom-tooltip"
				className={styles.customTooltip}
			>
				<div className={styles.analytics_tooltip}>
					<div className={styles.medium_font}>
						<span>
							{id}:
						</span>
						<span>
							{renderValue(units, value)}
							{' '}
							{renderUnit(units, value, intl.formatMessage)}
						</span>
					</div>
				</div>
			</div>
		);
	};

	const renderTooltipMain = (values, units) => <div
		data-spec="bar-chart-custom-tooltip"
		className={styles.customTooltipMain}
	>
			{renderTooltip(values, units)}
                                              </div>;

	return (
		<div
			data-spec="bar-chart"
			className={`${styles.chart_wrapper} ${(!isWide || !isAnalytics) &&
				styles.not_wide}`}
		>
			<main className={`${isAnalytics ? styles.main_analytics : styles.main}`}>
				<div className={styles.side_unit}>
					{renderUnit(gridItem.units, 0, intl.formatMessage)}
				</div>
				<ResponsiveBar
					data-spec="bar-chart"
					data={barChartData}
					keys={keys.length > 0 ? keys : ['value']}
					maxValue={maxValue * 1.2}
					margin={{
						top: 15,
						right: 30,
						bottom: 40,
						left: 60
					}}
					padding={0.5}
					colors={(data) => colorByKeys[data.id]}
					enableLabel={false}
					enableGridY={isAnalytics}
					axisBottom={{
						tickSize: 0,
						format: (value) => formatBottomAxis(value),
						tickPadding: 20,
						tickValues: showBottomAxis(),
					}}
					axisLeft={{
						format: (value) =>
							`${renderValue(gridItem.units, value, true)}`
					}}
					tooltip={(values) =>
						renderTooltipMain(
							values,
							gridItem.units,
							isHistoricalCycle(gridItem.groupByDateId),
							isTicketingCharts
						)}
				/>
			</main>
			<div className={styles.legend}>
				{keys.map((key) => (
					<div
						key={key}
						className={styles.legend_item}
						data-spec={`bar-chart-legend-item-${key}`}
					>
						<span
							className={styles.legend_dot}
							style={{
								backgroundColor: colorByKeys[key]
							}}
						/>
						<span>{key.length > 10 ? `${key.slice(0, 10)}...` : key}</span>
					</div>
				))}
			</div>
		</div>
	);
};

const {
	oneOfType,
	bool,
	objectOf,
	array,
	object,
	string,
	number,
	func,
	symbol,
} = PropTypes;

BarChart.propTypes = {
	chartData: oneOfType([object, array]),
	gridItem: objectOf(oneOfType([string, number, bool, object])),
	isAnalytics: bool,
	isWide: bool,
	isTicketingCharts: bool,
	intl: objectOf(oneOfType([object, func, string, symbol])),
	dataGridSize: string,
};

BarChart.defaultProps = {
	chartData: { data: [] },
	gridItem: {},
	isAnalytics: false,
	isWide: false,
	isTicketingCharts: false,
	intl: {},
	dataGridSize: 'lg',
};

export default injectIntl(BarChart);
