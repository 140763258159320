/* eslint-disable react/prop-types */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { find } from 'lodash';
import { push } from 'connected-react-router';
import store, { history } from '../redux/store';
import { localeSet } from '../redux/locale/actions';
import currentContext from './currentContext';
import { LOCALES } from './constants';

export const findEntitlement = (entitlements, networkEntitlementId) => {
	if (!networkEntitlementId) return;
	return find(
		entitlements,
		(entitlement) => entitlement.id === networkEntitlementId
	);
};

export const showValue = (value, Component) => {
	if (value !== undefined && value !== null) {
		return Component;
	}
};

export const trimInput = (blur) => (event) => {
	event.preventDefault();
	blur(event.target.name, event.target.value.trim());
};

export const onlyNumbers = (value) =>
	(value && Number.isNaN(Number(value)) ? (
		<FormattedMessage
			id="ONLY_NUMBERS_ARE_ALLOWED"
			defaultMessage="Only numbers are allowed"
		/>
	) : (
		undefined
	));

export const ConditionIf = ({ condition, children }) =>
	(condition ? children : null);

export const objectPropertiesValidation = (data, propertiesPath) => {
	const tempProperties = propertiesPath.split('.');
	let current = data;
	let validation = true;
	tempProperties.forEach((item) => {
		if (current && current[item] !== undefined) {
			current = current[item];
		} else {
			validation = false;
		}
	});
	return validation;
};

export const getCurrencyFormatWithCurrencyName = (currencyCode) => new Intl.NumberFormat(currentContext.locale || 'en-US', {
		style: 'currency',
		currency: currencyCode,
		currencyDisplay: 'code'
	});

export const getNumberFormat = (currencyCode) => new Intl.NumberFormat(currentContext.locale || 'en-US', {
		style: 'decimal',
		currency: currencyCode
	});

export const getCurrencyFormatWithCurrencySymbol = (currencyCode) => new Intl.NumberFormat(currentContext.locale || 'en-US', {
		style: 'currency',
		currency: currencyCode
	});

export const localizationHelper = (x) => x.trim().replace(/\)\s|\s\(|,\s|\s&\s|\s-\s|\s|\/|-|\)|'|\.\.\./g, '_').toUpperCase();

export const findUrlLanguage = () => {
  const language = find(LOCALES, {
		label: history.location.pathname.split('/')[1].toUpperCase(),
  });

  return language && language.label.toLowerCase();
};

export const setLanguageCode = (language) => {
	const urlItems = history.location.pathname.split('/');
	const foundLocal = find(LOCALES, {
		label: urlItems[1].toUpperCase(),
  });
  const languageValid = language && find(LOCALES, {
		label: language.toUpperCase(),
	});

	if (foundLocal) {
		urlItems[1] = language.toLowerCase();
	} else {
		urlItems.splice(1, 0, language.toLowerCase());
  }

  if (languageValid) {
    sessionStorage.setItem('language', language);
    store.dispatch(localeSet(languageValid.formattedLocal));
    store.dispatch(push(urlItems.join('/')));
  }
};

export const localizedRoute = (path) => {
  const locale = sessionStorage.getItem('language') || 'en-us';
  return push(`/${locale}${path}`);
};
